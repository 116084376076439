import {DataGrid} from "@mui/x-data-grid";
import {Auth} from "aws-amplify";
import {
    getMerchantFormList,
    getParticipantList,
    getStaffList
} from "../../services/AWS/competitionInformation/competitionInfo";
import {useEffect} from "react";
import {useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import Button from "@mui/material/Button";
import {generateCSV, generateParticipantCSV} from "../../ultis/masterData";

const getProperty = (obj, path) => {

    if (obj.hasOwnProperty(path)){
        return obj[path];
    }
    else{
        return 0;
    }

}

const MerchantFormListPage = () => {
    const [List, setItems] = useState([]);

    const getStaffListFromAPI = async () => {
        const userAuth = await Auth.currentSession();
        const MerchantList = await getMerchantFormList(userAuth.idToken.jwtToken);
        const rawJson = MerchantList.list;

        //loop
        for (let i = 0; i < rawJson.length; i++) {
            const productData = JSON.parse(rawJson[i].Product);
            if (productData.hasOwnProperty("BundleA")) {
                rawJson[i].BundleA = productData.BundleA;
            }
            if (productData.hasOwnProperty("BundleB")) {
                rawJson[i].BundleB = productData.BundleB;
            }
            if (productData.hasOwnProperty("Keychain")) {
                rawJson[i].Keychain = productData.Keychain;
            }
            if (rawJson[i].hasOwnProperty("TshirtSize") && rawJson[i].TshirtSize !== "") {
               const tshirtData = JSON.parse(rawJson[i].TshirtSize);
                rawJson[i].size_XS = getProperty(tshirtData,"XS");
                rawJson[i].size_S = getProperty(tshirtData,"S");
                rawJson[i].size_M = getProperty(tshirtData,"M");
                rawJson[i].size_L = getProperty(tshirtData,"L");
                rawJson[i].size_XL = getProperty(tshirtData,"XL");
                rawJson[i].size_XXL = getProperty(tshirtData,"XXL");
                rawJson[i].size_XXXL = getProperty(tshirtData,"XXXL");
            }
        }
        setItems(rawJson);

    }


    useEffect(() => {
        getStaffListFromAPI();

    },[]);

    const columns = [

        {field: 'Name', headerName: 'Full Name', width: 200},
        {field: 'Email', headerName: 'Email', width: 200},
        {field: 'createdAt', headerName: 'created At', width: 200},
        {field: 'amount', headerName: 'Total Amount(RM)', width: 200},
        {field: 'CurlecPaymentID', headerName: 'Stripe ID', width: 200},
        {field: 'BundleA', headerName: 'Bundle A', width: 200},
        {field: 'BundleB', headerName: 'Bundle B', width: 200},
        {field: 'Keychain', headerName: 'Keychain', width: 200},
        {field: 'TshirtSize', headerName: 'Tshirt Size', width: 200},
    ]

    const columnsNameString = [
        "Name",
        "Email",
        "createdAt",
        "amount",
        "CurlecPaymentID",
        "BundleA",
        "BundleB",
        "Keychain",
        "size_XS",
        "size_S",
        "size_M",
        "size_L",
        "size_XL",
        "size_XXL",
        "size_XXXL",
    ];


    const handleGenerateParticipantListClick = async() => {
        const particpantCsv =await generateCSV(List,columnsNameString);

        await downloadFile(particpantCsv,`AC2024_Purchase _Data`,"text/csv");
    }


    return (
        <div>
            <h1>Merchant Form List</h1>
            <DataGrid
                sx={{  width: '80vw',height: '80vh'}}
                columns={columns}
                getRowId={(row) => row.ID}
                pageSize={100}
                rows={List}
            />
            <Button variant="contained" color="secondary" onClick={handleGenerateParticipantListClick}>Generate Full
                 List</Button>
        </div>
    );
}

const downloadFile = (csvContent, fileName) => {

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE browser
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
    }
};




export default MerchantFormListPage;