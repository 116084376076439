import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PeopleIcon from '@mui/icons-material/People';
import BarChartIcon from '@mui/icons-material/BarChart';
import LayersIcon from '@mui/icons-material/Layers';
import AssignmentIcon from '@mui/icons-material/Assignment';

export const mainListItems = (
  <React.Fragment>
    <ListItemButton  href="/competitionList">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Competitions"/>
    </ListItemButton>
    <ListItemButton  href="/CreateCompetition">
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Create Comp"/>
    </ListItemButton>
      <ListItemButton  href="/ResultAnalyzer">
          <ListItemIcon>
              <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Result Analyzer"/>
      </ListItemButton>
      <ListItemButton  href="/MerchantFormList/ac2024">
          <ListItemIcon>
              <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Merchant List AC2024"/>
      </ListItemButton>
      <ListItemButton  href="/StaffList/ac2024">
          <ListItemIcon>
              <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Staff List AC2024"/>
      </ListItemButton>
      <ListItemButton  href="/ResendCompTicketPage">
          <ListItemIcon>
              <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Resend Email"/>
      </ListItemButton>


  </React.Fragment>
);

export const secondaryListItems = (
  <React.Fragment>

  </React.Fragment>
);
