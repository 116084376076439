import {DataGrid} from "@mui/x-data-grid";
import {Auth} from "aws-amplify";
import {getParticipantList, getStaffList} from "../../../services/AWS/competitionInformation/competitionInfo";
import {useEffect} from "react";
import {useState} from "react";
import { v4 as uuidv4 } from 'uuid';
import Button from "@mui/material/Button";
import {generateCSV, generateParticipantCSV} from "../../../ultis/masterData";

const RoleSelection = {
    PERSONAL_INFO: "personalInfo",
    PERSONAL_DETAILS: "personalDetails",
    STAFF_PREFERENCE: "staffPreference",
    REGISTER_SCORE_TAKING: "registerScoreTaking",
    JUDGES: "judges",
    SCRAMBLERS: "scramblers",
    MEDIA: "media",
    LIVESTREAM: "livestream",
    BENEFICIAL: "beneficial"
}

const selectedDaysTostring = (selectedDay) => {
    switch (selectedDay) {
        case "30th1421":
            return "30th Oct 14:00 - 21:00";
        case "31th1018":
            return "31th Oct 10:00 - 18:00";
        case "1Nov1022":
            return "1st Nov 10:00 - 22:00";
        case "2Nov1022":
            return "2nd Nov 10:00 - 22:00";
        case "3Nov1022":
            return "3rd Nov 10:00 - 22:00";
        case "4Nov1022":
            return "4th Nov 10:00 - 22:00";
        case "5Nov1018":
            return "5th Nov 10:00 - 18:00";
    }
}

const StaffListPage = () => {
    const [List, setItems] = useState([]);

    const getStaffListFromAPI = async () => {
        const userAuth = await Auth.currentSession();
        const staffList = await getStaffList("RubiksWCAAsianChampionship2024", userAuth.idToken.jwtToken);
        const rawJson = staffList.list;

        //convert the field  staffData which is string to json, plus the field of createdAt
        const staffData = rawJson.map((item) => {
            const staffData = JSON.parse(item.staffData);
            if (staffData.Role !== RoleSelection.SCRAMBLERS || !staffData.hasOwnProperty("ScamblerEventToParticipate")) {
                staffData.ScamblerEventToParticipate = [];
            }

            let daytoString = "";
            for (const key in staffData.selectedDays) {
            //only if true
            if (staffData.selectedDays[key]) {
                if (daytoString === "") {
                    daytoString = selectedDaysTostring(key);
                } else {
                    daytoString = daytoString + ", " + selectedDaysTostring(key);
              }

            }}
staffData.selectedDays = daytoString;

            return {
                ...staffData,
                ID:uuidv4(),
                createdAt: item.createdAt
            }
        })
        console.log(staffData);
        setItems(staffData);

}


    useEffect(() => {
        getStaffListFromAPI();

    },[]);

    const columns = [

        {field: 'fullName', headerName: 'Full Name', width: 200},
        {field: 'email', headerName: 'Email', width: 200},
        {field: 'Country', headerName: 'Country', width: 200},
        {field: 'dob', headerName: 'Date of Birth', width: 200},
        {field: 'Role', headerName: 'Role', width: 200},
        {field: 'competitionCount', headerName: 'Competition Count', width: 200},
        {field: 'wcaDelegate', headerName: 'WCA Delegate', width: 200},
        {field: 'createdAt', headerName: 'Created At', width: 200},
        {field: 'PreviousHelpedCompetitions', headerName: 'Previous Helped Competitions', width: 200},
        {field: 'PreviousOrgCompetitions', headerName: 'Previous Org Competitions', width: 200},
        {field: 'introduction', headerName: 'Introduction', width: 200},
        {field: 'PhoneNumber', headerName: 'Phone Number', width: 200},
        {field: 'typeWPM', headerName: 'Type WPM', width: 200},
        {field: 'SocialMediaLink', headerName: 'Social Media Link', width: 200},
        {field: 'remark', headerName: 'Remark', width: 200},
        {field: 'selectedDays', headerName: 'Selected Days', width: 200},
        {field: 'ScamblerEventToParticipate', headerName: 'Scambler Event To Participate', width: 200},
        {field: 'MealPreference', headerName: 'Meal Preference', width: 200},
        {field: 'OtherFood', headerName: 'Other Meal Preference', width: 200},
        {field: 'TshirtSize', headerName: 'Tshirt Size', width: 200}



        ]

    const columnsNameString = [
        "fullName",
        "email",
        "Country",
        "dob",
        "Role",
        "competitionCount",
        "wcaDelegate",
        "createdAt",
        "PreviousHelpedCompetitions",
        "PreviousOrgCompetitions",
        "introduction",
        "PhoneNumber",
        "typeWPM",
        "SocialMediaLink",
        "remark",
        "selectedDays",
        "ScamblerEventToParticipate",
        "MealPreference",
        "OtherFood",
        "TshirtSize"
    ];


    const handleGenerateParticipantListClick = async() => {
        const particpantCsv =await generateCSV(List,columnsNameString);

        await downloadFile(particpantCsv,`AC2024_Staff_Data`,"text/csv");
    }


    return (
        <div>
            <DataGrid
                sx={{  width: '80vw',height: '80vh'}}
                columns={columns}
                getRowId={(row) => row.ID}
                pageSize={100}
                rows={List}
            />
            <Button variant="contained" color="secondary" onClick={handleGenerateParticipantListClick}>Generate Full
                Staff List</Button>
        </div>
    );
}

const downloadFile = (csvContent, fileName) => {

    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        // For IE browser
        window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.download = fileName;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(objectUrl);
    }
};




export default StaffListPage;